/* eslint-disable */
import VeeValidate from 'vee-validate';

VeeValidate.Validator.extend( 'date_valid', {
    getMessage: field => 'The ' + field + ' value is not truthy.',
    validate: value =>
    {
        let parts = value.split( '-' );
        let day = parseInt( parts[ 2 ] );
        let month = parseInt( parts[ 1 ] - 1 );
        let year = parseInt( parts[ 0 ] );
        let date = new Date( year, month, day, 0, 0, 0 );

        return day === date.getDate() && month === date.getMonth() && year === date.getFullYear();
    }
});

VeeValidate.Validator.extend( 'date_before', {
    getMessage: ( field, val ) => 'The ' + field + ' value must be earlier than minDate',
    validate: value =>
    {
        let parts = value.split( '-' );
        let day = parseInt( parts[ 2 ] );
        let month = parseInt( parts[ 1 ] - 1 );
        let year = parseInt( parts[ 0 ] );
        let date = new Date( year, month, day, 0, 0, 0 );

        let today = new Date();
        let before = new Date();
        before.setYear( today.getFullYear() - 18 );

        return ( before - date >= 0 );
    }
});

export default {
    gender: { required: true },
    firstname: { required: true, min: 2, regex: /^[A-Za-z0-9 ]*$/i },
    infixname: { required: false, min: 1, regex: /^[A-Za-z0-9 \']*$/i },
    surname: { required: true, min: 2, regex: /^[A-Za-z0-9 ]*$/i },

    date_of_birth: { required: true, date_valid: true, date_before: true },

    zipcode: { required: true, regex: /^[1-9][0-9]{3} {0,1}[A-Za-z]{2}$/ },
    house_num: { required: true, min: 1, min_value: 1, max: 5 },
    house_num_add: { required: false, regex: /^[A-Za-z0-9 ]*$/i, max: 30 },

    telephone: { required: false, regex: /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/ },
    emailaddress: { required: true, email: true }
};
