<template>
    <div :class="{ 'custom-radio': true, 'has-errors': errors.has( name ) }">
        <div class="row">
            <div class="col-6" v-for="(radioValue, index) in radioValues">
                <input type="radio" :id="name + '-radio' + index" :name="name" v-model="value" :value="radioValue" v-validate="name ? rules[ name ] : null" @change="onChange" :tabindex="parseInt( tabindexStart, 10 ) + (index)"/>
                <div class="indicator"></div>
                <label :for="name + '-radio' + index">{{ radioLabels ? radioLabels[index] : radioValue }}</label>
            </div>
        </div>

        <span class="error">{{ errors.first( name ) }}</span>
    </div>
</template>

<style lang="scss" scoped>
    .col-6{
        position:relative;
    }
</style>

<script>
    import Vue from 'vue';
    import { rules } from '@/app/config';
    import { tracking } from '@/app/mixins';

    export default {
        inject: [ '$validator' ],

        mixins: [ tracking ],

        model: {
            prop: 'selected',
            event: 'change'
        },

        props: {
            selected: {
                type: String,
                default: null
            },

            name: {
                type: String,
                default: 'radio'
            },

            radioValues: {
                type: Array,
                default: () => [ 'yes', 'no' ]
            },

            radioLabels: {
                type: Array,
                default: () => [ 'Ja', 'Nee' ]
            },

            tabindexStart: {
                type: String,
                default: ''
            }
        },

        data()
        {
            return {
                rules: rules,
                value: this.selected
            };
        },

        mounted()
        {
            Vue.nextTick( () =>
            {
                this.value = this.selected;
            });
        },

        methods: {
            onChange( e )
            {
                // if( this.radioValue === e.target.value )
                // {
                //     this.checked = true;
                // }

                this.trackEvent( 'Klik op input', 'Funnel events', 'Aanhef' );

                this.$emit( 'change', e.target.value );
            }
        }
    };
</script>
