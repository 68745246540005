<template>
    <div :class="{ 'date-of-birth-select': true, 'custom-input': true, 'has-errors': errors.has( 'date_of_birth' ) }">
        <label class="date-label">Geboortedatum</label>
        <div class="row">
            <div class="col-4">
                <v-select class="date-inputs" name="day-of-birth" v-model="dayOfBirth" :selectValues="days" :selectLabels="dayLabels"></v-select>
            </div>
            <div class="col-4">
                <v-select class="date-inputs" name="month-of-birth" v-model="monthOfBirth" :selectValues="months" :selectLabels="monthLabels"></v-select>
            </div>
            <div class="col-4">
                <v-select class="date-inputs" name="year-of-birth" v-model="yearOfBirth" :selectValues="years"></v-select>
            </div>
        </div>

        <input type="hidden" name="date_of_birth" v-model="date_of_birth" v-validate="rules['date_of_birth']" />

        <span class="error">{{ errors.first( 'date_of_birth' ) }}</span>
    </div>
</template>

<style lang="scss" scoped>
    .date-label{
        display:block;
    }
    .date-inputs{
        display:block;
    }
</style>

<script>
    /* eslint-disable vue/prop-name-casing */
    import { rules } from '@/app/config';
    import CustomSelect from '@/app/components/form-elems/CustomSelect';

    export default {
        inject: ['$validator'],

        components: {
            'v-select': CustomSelect
        },

        model: {
            prop: 'date_of_birth',
            event: 'change'
        },

        props: {
            date_of_birth: {
                type: String,
                default: null
            }
        },

        data()
        {
            return {
                rules: rules,

                days: [],
                dayLabels: [],

                months: [],
                monthLabels: [
                    'januari',
                    'februari',
                    'maart',
                    'april',
                    'mei',
                    'juni',
                    'juli',
                    'augustus',
                    'september',
                    'oktober',
                    'november',
                    'december'
                ],
                years: [],

                dayOfBirth: '',
                monthOfBirth: '',
                yearOfBirth: ''
            };
        },

        watch: {
            'dayOfBirth': function( pVal )
            {
                this.checkDate();
            },

            'monthOfBirth': function( pVal )
            {
                this.checkDate();
            },

            'yearOfBirth': function( pVal )
            {
                this.checkDate();
            },

            'date_of_birth': function( pVal )
            {
                this.prefill();
            }
        },

        mounted()
        {
            for( let i = 0; i < 31; i++ )
            {
                this.days.push( ( '0' + ( i + 1 ) ).substr( -2 ) );
                this.dayLabels[ i ] = i + 1; //  = i + 1;
            }

            for( let i = 0; i < 12; i++ )
            {
                this.months.push( ( '0' + ( i + 1 ) ).substr( -2 ) );
            }

            // console.log( this.months );

            for( let i = 2002; i > 1900; i-- )
            {
                this.years.push( i );
            }
        },

        methods: {
            checkDate()
            {
                if( this.dayOfBirth === '' || this.monthOfBirth === '' || this.yearOfBirth === '' )
                {
                    return;
                    // nog niet klaar met invullen
                }

                /* dit moet voor laravel: */
                // this.monthOfBirth = ( '0' + this.monthOfBirth ).substr( -2 );
                // this.dayOfBirth = ( '0' + this.dayOfBirth ).substr( -2 );

                this.$emit( 'change', this.yearOfBirth + '-' + this.monthOfBirth + '-' + this.dayOfBirth );
            },

            prefill()
            {
                if( this.date_of_birth && ( !this.dayOfBirth && !this.monthOfBirth && !this.yearOfBirth ) )
                {
                    let parts = this.date_of_birth.split( '-' );
                    this.yearOfBirth = parts[0];
                    this.monthOfBirth = parts[1];
                    this.dayOfBirth = parts[2];
                }
            }
        }
    };
</script>
