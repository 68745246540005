<template>
    <div class="timer-wrapper">
        <!-- <img class="nl-banner" src="/static/img/banner.png" /> -->

        <img class="banner" src="/static/img/banner.png" />
        <span :style="{ 'font-size': tickerFontSize }" class="time">{{ time }}</span>
        <img class="clock" unselectable="on" ref="clock" src="/static/img/timer.png" />
    </div>
</template>

<style lang="scss" scoped>
    .timer-wrapper{
        z-index:3;
    }
</style>

<script>
    import Vue from 'vue';
    import { TweenMax, TimelineMax } from '@/vendor';

    export default {
        props: {
            time: {
                type: Number,
                default: 50
            }
        },

        data()
        {
            return {
                // timeLeft: 50,
                tickerFontSize: '1rem'
            };
        },

        // watch: {
        //     time: function( pVal )
        //     {
        //         if( pVal < 5 )
        //         {
        //             this.almostUpAnim();
        //         }
        //     }
        // },

        mounted()
        {
            this.animStarted = false;
            this.functionRef = this.adjustFontsize;
            window.addEventListener( 'resize', this.functionRef );

            Vue.nextTick( () =>
            {
                this.adjustFontsize();
            });
        },

        destroyed()
        {
            window.removeEventListener( 'resize', this.functionRef );
        },

        methods: {
            adjustFontsize()
            {
                let width = this.$refs.clock.getBoundingClientRect().width;
                width *= 0.35;
                this.tickerFontSize = width + 'px';
            },

            almostUpAnim()
            {
                if( this.animStarted )
                {
                    return;
                }

                this.animStarted = true;
                TweenMax.set( '.timer-wrapper', { transformOrigin: '50% 70%', rotation: 3 });
                let tl = new TimelineMax({ repeat: -1 });

                tl.to( '.timer-wrapper', 0.1, { rotation: -5 });
                tl.to( '.timer-wrapper', 0.1, { rotation: 5 });

                tl.timeScale( 1 );
            }
        }
    };
</script>
