import QuizData from '@/app/data/quiz.json';

var shuffle = function( array )
{
    for( let i = array.length - 1; i > 0; i-- )
    {
        const j = Math.floor( Math.random() * ( i + 1 ) );
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

export default {
    namespaced: true,

    state: {
        quizData: QuizData,
        answers: [],
        replay: false,
        noLimit: false
    },
    getters: {
        answers: state => state.answers,
        questions: state => ( id ) =>
        {
            return state.quizData[ id ];
        },
        numQuestions: state => state.quizData.length,
        numCorrect: state =>
        {
            let numCorrect = 0;
            for( let answer of state.answers )
            {
                let questionContext = state.quizData.find( question =>
                {
                    return question.id === answer.questionId;
                });

                // let givenAnswer = questionContext.options.find( option =>
                // {
                //     return option.id === answer.option;
                // });

                if( answer.answer === questionContext.correct )
                {
                    numCorrect++;
                }
            }

            return numCorrect;
        },

        isReplay: state => state.replay,
        noLimit: state => state.noLimit
    },
    mutations: {
        pushAnswer: ( state, payload ) =>
        {
            state.answers.push( payload );
        },

        resetAnswers: state =>
        {
            state.answers = [];
        },

        randomizeQuestions: state =>
        {
            shuffle( state.quizData );
        },

        updateImageUrl: ( state, payload ) =>
        {
            for( let item of payload )
            {
                let questionId = item.fileName.match( /(\d)+/g )[0];

                let questionData = state.quizData.find( item => item.id === parseInt( questionId ) );
                questionData.visual = item.blobUrl;
            }
            // for( let question of state.quizData )
            // {
            //     console.log( question );
            // }
            // state.quizData
        },

        setReplay: ( state, payload ) =>
        {
            state.replay = payload;
        },

        setNoLimit: state =>
        {
            state.noLimit = true;
        }
    },
    actions: {
        answerGiven: ( context, payload ) =>
        {
            context.commit( 'pushAnswer', payload );
        },

        reset: context =>
        {
            if( context.getters.noLimit === false )
            {
                context.commit( 'randomizeQuestions' );
            }

            context.commit( 'resetAnswers' );
        },

        updateImages: ( context, payload ) =>
        {
            context.commit( 'updateImageUrl', payload );
        },

        setReplay: ( context, payload ) =>
        {
            context.commit( 'setReplay', payload );
        },

        setNoLimit: context =>
        {
            context.commit( 'setNoLimit' );
        }
    }
};
