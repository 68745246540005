<template>
    <div class="share-view">
        <v-frame class="full-gold">
            <v-timer slot="top"></v-timer>

            <div class="gold">
                <h3>Het is gelukt!</h3>
                <p>We hebben je gegevens goed ontvangen.</p>
                <h3>Wil je 5 extra winkansen verdienen?</h3>
                <p>Daag dan je vrienden uit voor dit spel!</p>

                <h3>Daag uit</h3>
                <div class="icon-wrapper">
                    <v-social></v-social>
                </div>
            </div>
            <router-link slot="bottom" tag="button" to="bedankt" class="continue-btn">Bekijk je winkansen</router-link>
        </v-frame>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Frame from '@/app/components/Frame';
    import Timer from '@/app/components/Timer';
    import Social from '@/app/components/Social';

    export default {
        components: {
            'v-frame': Frame,
            'v-timer': Timer,
            'v-social': Social
        }
    };
</script>
