<template>
    <div :class="{ 'custom-select': true, 'has-errors': errors.has( name ) }">

        <label :for="name"><slot>{{ labels[ name ] }}{{ ( rules[ name ] && rules[ name ].required ) ? '*' : '' }}</slot></label>

        <select
            :id="name + '-select'"
            :name="name"

            v-model="value"
            v-validate="name ? rules[ name ] : null"

            :data-value="value"

            @change="onChange" >
            <option value="" disabled selected>{{ placeholders[ name ] }}</option>
            <template v-for="( selectValue, key ) in selectValues">
                <option :value="selectValue" >{{ selectLabels ? selectLabels[ key ] : selectValue }}</option>
            </template>
        </select>

        <!-- <span class="error">{{ errors.first( name ) }}</span> -->
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { rules, labels, placeholders } from '@/app/config';

    export default {
        inject: [ '$validator' ],

        model: {
            prop: 'selected',
            event: 'change'
        },

        props: {
            selected: {
                type: String,
                default: null
            },

            name: {
                type: String,
                default: 'select'
            },

            selectValues: {
                type: Array,
                default: () => [ 0, 1 ]
            },

            selectLabels: {
                type: Array,
                default: null
            }
        },

        data()
        {
            return {
                rules: rules,
                labels: labels,
                placeholders: placeholders,
                value: this.selected
            };
        },

        watch: {
            'selected': function( pVal )
            {
                this.value = pVal;
            }
        },

        methods: {
            onChange( e )
            {
                this.$emit( 'change', e.target.value );
            }
        }
    };
</script>
