<template>
    <div class="thanks-view">
        <v-frame class="full-gold">
            <v-timer slot="top"></v-timer>

            <div class="gold">
                <h2>Het is gelukt!</h2>
                <p>We hebben je deelname goed ontvangen.</p>
                <p>In de week van <strong>13&nbsp;oktober</strong> worden <strong>50&nbsp;winnaars</strong> getrokken uit alle deelnemers. Je ontvangt persoonlijk bericht als je één van de gelukkige bent. Het <strong>Straatje&nbsp;Staatsloten</strong> wordt dan zo snel mogelijk naar je opgestuurd.</p>
            </div>

            <button slot="bottom" @click="playAgain">Speel opnieuw</button>
        </v-frame>
    </div>
</template>

<style lang="scss" scoped>
    .extra-margin{
        margin-top:2rem;
    }
</style>

<script>
    import Frame from '@/app/components/Frame';
    import Timer from '@/app/components/Timer';
    import { browserCheck, recaptcha } from '@/app/mixins';

    export default {
        components: {
            'v-frame': Frame,
            'v-timer': Timer
        },

        mixins: [ browserCheck, recaptcha ],

        computed: {
            numCorrect()
            {
                return this.$store.getters[ 'quiz/numCorrect' ];
            },

            answersGiven()
            {
                return this.$store.getters[ 'quiz/answers' ].length;
            },

            hasShared()
            {
                return false;
                // if( !this.$store.getters['entry/get'] )
                // {
                //     return false;
                // }
                // return this.$store.getters['entry/get'].has_shared;
            },

            shareAmount()
            {
                return 5;
            }
        },

        methods: {
            playAgain()
            {
                this.$store.dispatch( 'quiz/setReplay', true );
                this.$store.dispatch( 'quiz/reset' );
                this.$store.dispatch( 'entry/reset' );
                this.$router.push( '/quiz' );
            }
        }
    };
</script>
