<template>
    <div>
        <div class="social">
            <a v-if="browserCheck.isMobile" :href="whatsappShareLink" target="_blank" @click="trackWhatsapp"><div class="item" data-platform="whatsapp"></div></a>
            <div @click="shareIntent" data-platform="facebook" class="item"></div>
            <div @click="shareIntent" data-platform="twitter" class="item"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { tracking, browserCheck, socialShare, recaptcha } from '@/app/mixins';

    export default {
        components: {

        },

        mixins: [ tracking, browserCheck, socialShare, recaptcha ],

        computed: {
            winchances()
            {
                return this.$store.getters[ 'quiz/numCorrect' ];
            },

            entryId()
            {
                return this.$store.getters[ 'entry/getId' ];
            },

            shareUrl()
            {
                return window._rootData.apiUrl; // + '/share/' + this.entryId;
            },

            whatsappShareLink()
            {
                return this.socialShare.url( 'whatsapp', this.shareUrl, { text: 'https://staatsloterij.nl/wat-weet-jij-van-nederland' });
            }
        },

        methods: {
            shareIntent( e )
            {
                let platform = e.target.dataset.platform.replace( /^([a-z])/, r => r.toUpperCase() );
                this.trackEvent( 'Klik op share button - ' + platform, 'Link tracking', this.shareUrl );

                switch( e.target.dataset.platform )
                {
                        case 'facebook':
                            this.socialShare.share( 'facebook', this.shareUrl, {}, this.shareCallback );
                            break;
                        case 'twitter':
                            // Ik heb X winkansen gewonnen door `50 Seconds` van Staatsloterij te spelen! Ook kans maken?
                            this.socialShare.share( 'twitter', this.shareUrl, { text: 'Speel het spel "Wat weet jij van Nederland?" en maak kans op 1 van de 50 Straatjes Staatsloten! Ga snel naar: https://staatsloterij.nl/wat-weet-jij-van-nederland' }, this.shareCallback );
                            break;
                        default:
                            break;
                }
            },

            async shareCallback()
            {
                let obj = {};
                obj[ 'g-recaptcha-response' ] = await this.executeRecaptcha();
                this.$store.dispatch( 'entry/updateShare', obj ).then( result =>
                {
                    if( result.success === true )
                    {
                        this.$store.dispatch( 'entry/merge', { has_shared: true });
                        this.$router.push( '/bedankt' );
                    }
                })
                    .catch( error =>
                    {
                        this.waitingForApi = false;
                        console.log( error );
                    });
            },

            // async nativeShare()
            // {
            //     this.shareUpdate = {
            //         'g-recaptcha-response': null
            //     };

            //     if( navigator.share )
            //     {
            //         navigator.share({
            //             title: '50 Seconds!',
            //             text: 'Ik heb ' + this.numCorrect + ' winkansen gewonnen door `50 Seconds` van Staatsloterij te spelen!',
            //             url: window.location.origin
            //         })
            //             .then( () => {})
            //             .catch( ( error ) => { console.log( error ); });
            //     }

            //     this.shareUpdate['g-recaptcha-response'] = await this.executeRecaptcha();
            //     this.$store.dispatch( 'entry/updateShare', this.shareUpdate );
            // },

            trackWhatsapp( e )
            {
                this.trackEvent( 'Klik op share button - Whatsapp', 'Link tracking', this.whatsappShareLink );
            }
        }
    };
</script>
