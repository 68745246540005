<template>
    <div class="naw-view">
        <div class="content-wrapper">
            <h2>Goed gedaan!</h2>
            <p>Registreer je deelname en je ziet direct <br/>hoeveel winkansen je bij elkaar gespeeld hebt!</p>
            <v-form @complete="onFormComplete"></v-form>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Form from '@/app/components/Form';

    export default {

        components: {
            'v-form': Form
        },

        data()
        {
            return {
            };
        },

        mounted()
        {
        },

        methods: {
            onFormComplete()
            {
                // this.$router.push( '/bedankt' );
                this.$router.push( '/result' );
            }
        }
    };
</script>
