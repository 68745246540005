export default {
    firstname: 'bv. Jan',
    infixname: 'bv. van',
    surname: 'bv. Jansen',
    'day-of-birth': 'dag',
    'month-of-birth': 'maand',
    'year-of-birth': 'jaar',
    zipcode: 'bv. 1234 AB',
    house_num: 'bv. 12',
    house_num_add: 'bv. 2h',
    emailaddress: 'bv. janjansen@hotmail.com',
    telephone: 'bv. 06 1234 5678'
};
