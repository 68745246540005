<template>
    <div class="form-step2">
        <h2>Je bent er bijna...</h2>

        <div class="indicator-wrapper">
            <div class="dot active">1</div>
            <div class="line"></div>
            <div class="dot">2</div>
            <div class="line hide"></div>
            <div class="dot hide">3</div>
        </div>

        <div class="form-wrapper">
            <form @submit.prevent="onSubmit">
                <div class="row address-row">
                    <div class="col-12">
                        <v-input name="zipcode" @blur="checkAddress" v-model="entry.zipcode" ></v-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <v-input name="house_num" @blur="checkAddress" v-model="entry.house_num" ></v-input>
                    </div>
                    <div class="col-6">
                        <v-input name="house_num_add" @blur="checkAddress" v-model="entry.house_num_add" ></v-input>
                    </div>
                </div>

                <div class="address-error-row">
                    <span class="address-error error" v-if="addressError">Dit is geen geldig adres</span>
                    <span class="address-preview" v-if="entry.street" >{{ entry.street + ', ' + entry.city }}</span>
                    <!-- <input class="hidden" v-model="entry.street" />
                    <input class="hidden" v-model="entry.city" /> -->
                    <v-input class="not-visible" name="street" v-model="entry.street" ></v-input>
                    <v-input class="not-visible" name="city" v-model="entry.city" ></v-input>
                </div>

                <button type="submit">Naar contactgegevens</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .form-wrapper{
        margin:0 auto;
        width:100%;
        max-width:400px;

        button{
            margin:0 auto;
            display:block;
        }
    }

    .not-visible{
        display:none;
    }
</style>

<script>
    import Vue from 'vue';
    import CustomInput from '@/app/components/form-elems/CustomInput';

    export default {
        components: {
            'v-input': CustomInput
        },

        data()
        {
            return {
                entry: {
                    zipcode: null,
                    street: null,
                    city: null,
                    house_num: null,
                    house_num_add: null
                },

                addressError: false,
                waitingForApi: false
            };
        },

        computed: {
            savedEntry()
            {
                return this.$store.getters['entry/get'];
            }
        },

        watch: {
            'entry.zipcode': function( pVal )
            {
                if( pVal )
                {
                    // pVal = pVal.toUpperCase();
                    this.entry.zipcode = pVal.toUpperCase();
                }
            }
        },

        mounted()
        {
            if( this.savedEntry )
            {
                this.entry.zipcode = this.savedEntry.zipcode;
                this.entry.house_num = this.savedEntry.house_num;
                this.entry.house_num_add = this.savedEntry.house_num_add;
            }
        },

        methods: {
            stripSpaces( e )
            {
                this.entry[ e.target.name ] = e.target.value.replace( /[\s ]/g, '' );
                Vue.nextTick( () =>
                {
                    this.$validator.validate( e.target.name, this.entry[ e.target.name ] );
                });
            },

            checkAddress( e )
            {
                if( e )
                {
                    this.stripSpaces( e );
                }

                if( !this.entry.zipcode || !this.entry.house_num )
                {
                    return;
                }

                if( this.lastAddress === this.entry.zipcode + this.entry.house_num + this.entry.house_num_add )
                {
                    return;
                }
                this.addressError = false;
                this.lastAddress = this.entry.zipcode + this.entry.house_num + this.entry.house_num_add;
                if( this.entry.zipcode.length > 5 && this.entry.house_num.length > 0 )
                {
                    let url = window._rootData.apiUrl + '/api/address/' + this.entry.zipcode + '/' + this.entry.house_num;
                    if( this.entry.house_num_add && this.entry.house_num_add.length > 0 )
                    {
                        url += '/' + this.entry.house_num_add;
                    }
                    this.waitingForAddress = true;
                    Vue.$http.post( url ).then( ( result ) =>
                    {
                        if( result.data.errorCode )
                        {
                            throw new Error( result.data.message );
                        }
                        this.entry.street = result.data.street;
                        this.entry.city = result.data.city;
                        this.waitingForAddress = false;
                    }).catch( ( result ) =>
                    {
                        this.addressError = true;
                        this.entry.street = null;
                        this.entry.city = null;
                        this.waitingForAddress = false;
                    });
                }
            },

            validate()
            {
                return this.$validator.validateAll( Object.keys( this.entry ) ).then( result =>
                {
                    console.log( result, this.$validator.errors );
                });
            },

            async onSubmit()
            {
                await this.validate();

                if( !this.$validator.errors.any() )
                {
                    await this.checkAddress();

                    if( this.addressError === false )
                    {
                        this.$store.dispatch( 'entry/merge', this.entry );
                        this.$router.push( '/form3' );
                    }
                }
            }
        }
    };
</script>
