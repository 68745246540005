<template>
    <div :class="{ 'main-layout': true, [ $route.name ? $route.name.toLowerCase() + '-route' : 'idk-route' ]: true }">
        <div class="stl-bar">
            <a class="clickout" href="https://staatsloterij.nederlandseloterij.nl/" target="_blank" @click="trackLogo"><img src="/static/img/logos/stl-label.png" /></a>
        </div>

        <!-- <h1 class="main-header" v-if="preNaw" >Wat weet jij van Nederland?</h1> -->

        <div class="content">
            <svg class="golden-border" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + wWidth + ' ' + wHeight" preserveAspectRatio="none" >
                <defs>
                    <linearGradient id="gold-gradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse" gradientTransform="rotate(-15)">
                        <stop offset="0%" stop-color="#e3bd52"/>
                        <stop offset="100%" stop-color="#9d681b"/>
                    </linearGradient>
                    <clipPath id="kijkgat">
                        <rect x="0" y="0" :width="wWidth" :height="borderWidth" />
                        <rect x="0" y="0" :width="borderWidth" height="100%" />
                        <rect :x="( wWidth - borderWidth ) + 'px'" y="0" :width="borderWidth" height="100%" />
                        <rect x="0" y="97%" :width="wWidth" height="3%" />
                        <!-- <polygon :points="polyPath" /> -->
                    </clipPath>
                </defs>
                <rect x="0" y="0" width="100%" height="100%" fill="url(#gold-gradient)" clip-path="url(#kijkgat)" />
            </svg>
            <slot></slot>
        </div>

        <footer>
            <!-- a class="clickout" href="https://staatsloterij.nederlandseloterij.nl/" target="_blank">staatsloterij.nl</a -->
            <div class="content-wrapper">
                <div class="img-wrapper">
                    <!-- a class="speel-bewust-link" target="_blank" href="https://www.nederlandseloterij.nl/speel-bewust?sessionGUID=2c2a5c61-b496-2eca-24de-7a98d242ae01&webSyncID=25e33482-2288-23b1-d9d5-18c7b2575617&sessionGUID=2c2a5c61-b496-2eca-24de-7a98d242ae01">
                        <img class="footer-img" src="/static/img/logos/speel-bewust.png" />
                    </a -->
                    <img class="footer-img" src="/static/img/logos/footer2.png" />
                    <!-- div class="mopinion-wrapper">
                        <a target="_blank" href="https://informatie.nederlandseloterij.nl/feedback/Staatsloterij-email-feedback/?rating=negatief&mailingName=1juli-droomsalarisquiz"><img src="/static/img/logos/mop1.png" alt="" /></a>
                        <a target="_blank" href="https://informatie.nederlandseloterij.nl/feedback/Staatsloterij-email-feedback/?rating=positief&mailingName=1juli-droomsalarisquiz"><img src="/static/img/logos/mop2.png" alt="" /></a>
                    </div -->
                </div>
                <p class="disclaimer">Voor het organiseren van de Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk.<br />Deelname vanaf 18 jaar. *Als je aan deze actie meedoet, dan accepteer je onze <a @click="trackAv" href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a> en <a @click="trackPrivacyClickout" href="https://stlproduction.blob.core.windows.net/assets/940DD7DF-25DB-427D-8B30-D0F9A77CC6C4" target="_blank">Privacy Statement</a> van de Nederlandse Loterij.</p>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { tracking } from '@/app/mixins';

    export default {
        mixins: [ tracking ],

        data()
        {
            return {
                wHeight: document.documentElement.getBoundingClientRect().height,
                wWidth: window.innerWidth,
                borderWidth: 20,
                contentHeight: 0
            };
        },

        computed: {
            preNaw()
            {
                return [ 'Landing', 'Example', 'Quiz' ].indexOf( this.$route.name ) > -1;
            },
            polyPath()
            {
                return '0,' + this.wHeight + ' ' + this.wWidth + ',' + this.wHeight + ' ' + this.wWidth + ',' + ( this.contentHeight ) + ' 0,' + ( this.contentHeight );
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            this.onResize();

            // setTimeout( this.onResize, 1150 );
        },

        methods: {
            onResize()
            {
                this.wHeight = document.documentElement.getBoundingClientRect().height;
                this.wWidth = window.innerWidth;
                this.contentHeight = document.querySelector( '.main-layout > .content' ).getBoundingClientRect().height;
            },

            trackPrivacyClickout()
            {
                this.trackEvent( 'Klik op link - Privacy Statement', 'Link tracking', 'Nieuw venster - Privacy Statement - https://www.nederlandseloterij.nl/privacy' );
            },

            trackAv()
            {
                this.trackEvent( 'Klik op link - Actievoorwaarden', 'Link tracking', window.location.origin + this.avLink );
            },

            trackLogo()
            {
                this.trackEvent( 'Klik op link - Logo', 'Link tracking', 'Nieuw venster - Staatsloterij - https://staatsloterij.nederlandseloterij.nl/' );
            }
        }
    };
</script>
