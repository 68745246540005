export default {
    gender: 'Aanhef',
    firstname: 'Voornaam',
    infixname: 'Tussenvoegsel',
    surname: 'Achternaam',
    day_of_birth: 'dag',
    month_of_birth: 'maand',
    year_of_birth: 'jaar',
    zipcode: 'Postcode',
    house_num: 'Huisnr.',
    house_num_add: 'Toevoeging',
    emailaddress: 'E-mailadres',
    telephone: 'Telefoonnummer',
    street: 'Straat',
    city: 'Plaats'
};
