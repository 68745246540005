<template>
    <div class="thanks-view">
        <v-frame class="full-gold">
            <v-timer slot="top"></v-timer>

            <div class="gold">
                <h2>He jammer...</h2>
                <p>De 1 juli - Droomsalarisquiz is helaas afgelopen!</p>
                <p>In de week van <strong>13&nbsp;juli</strong> worden <strong>50&nbsp;winnaars</strong> getrokken uit alle deelnemers. Je ontvangt persoonlijk bericht als je één van de gelukkige bent. Het <strong>Straatje&nbsp;Staatsloten</strong> wordt dan voor de 10 augustus trekking 2020 zo snel mogelijk naar je opgestuurd.</p>
            </div>

            <a href="https://staatsloterij.nederlandseloterij.nl/" target="_blank" class="button" slot="bottom">Naar Staatsloterij</a>
        </v-frame>
    </div>
</template>

<style lang="scss" scoped>
    .extra-margin{
        margin-top:2rem;
    }

    h2{
        font-size:1.5rem;

        & + p {
            margin-top:0.5rem;
        }
    }

    a{
        text-decoration: none;
        display:block;
    }
</style>

<script>
    import Frame from '@/app/components/Frame';
    import Timer from '@/app/components/Timer';

    export default {
        components: {
            'v-frame': Frame,
            'v-timer': Timer
        }
    };
</script>
