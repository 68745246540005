<template>
    <div>
        <form @submit.prevent="onSubmit">
            <!-- <v-select name="prize" v-model="entry.prize" :selectValues="[ 'tv', 'speaker', 'keyboard', 'mouse' ]"></v-select> -->
            <!-- <v-radio name="gender" v-model="entry.gender" :radioValues="[ 'male', 'female', 'other' ]" ></v-radio> -->

            <v-radio name="gender" v-model="entry.gender" :radioValues="[ 'male', 'female' ]" :radioLabels="[ 'Man', 'Vrouw' ]" tabindexStart="1" ></v-radio>

            <div class="row">
                <div class="col-8">
                    <v-input name="firstname" v-model.trim="entry.firstname" ></v-input>
                </div>
                <div class="col-4">
                    <v-input name="infixname" v-model.trim="entry.infixname" ></v-input>
                </div>
            </div>

            <v-input name="surname" v-model="entry.surname" ></v-input>

            <v-date-of-birth name="date_of_birth" v-model="entry.date_of_birth"></v-date-of-birth>
            <p class="small-text">Alleen als je 18 jaar of ouder bent mag je meespelen.</p>

            <div class="row address-row">
                <div class="col-6">
                    <v-input name="zipcode" @blur="checkAddress" v-model.trim="entry.zipcode" ></v-input>
                </div>
                <div class="col-3">
                    <v-input name="house_num" @blur="checkAddress" v-model.trim="entry.house_num" ></v-input>
                </div>
                <div class="col-3">
                    <v-input name="house_num_add" @blur="checkAddress" v-model.trim="entry.house_num_add" ></v-input>
                </div>
            </div>

            <div class="address-error-row">
                <span class="address-error error" v-if="addressError">Dit is geen geldig adres</span>
                <span class="address-preview" v-if="entry.street" >{{ entry.street + ', ' + entry.city }}</span>
                <!-- <input class="hidden" v-model="entry.street" />
                <input class="hidden" v-model="entry.city" /> -->
                <v-input class="not-visible" name="street" v-model="entry.street" ></v-input>
                <v-input class="not-visible" name="city" v-model="entry.city" ></v-input>
            </div>
            <p class="small-text">Kloppen je adresgegevens nog? Dit adres wordt gebruikt om de eventuele prijs naartoe te sturen.</p>

            <v-input class="telephone-row" name="telephone" v-model.trim="entry.telephone" ></v-input>
            <p class="small-text">Vul je telefoonnummer in en ontvang &eacute;&eacute;nmalig een telefonisch aanbod van Staatsloterij of Lotto om automatische mee te spelen.</p>

            <v-input class="email-row" name="emailaddress" v-model.trim="entry.emailaddress" ></v-input>
            <p class="small-text">We nemen persoonlijk contact met je op, als je hebt gewonnen.</p>

            <v-checkbox name="kit" v-model="entry.kit" >
                Ik wil per e-mail relevante aanbiedingen van de (toekomstige) kansspelen van Nederlandse Loterij ontvangen (dit kun je later altijd wijzigen)
            </v-checkbox>

            <button type="submit" :disabled="waitingForApi">Verstuur <v-spinner v-if="waitingForApi"></v-spinner></button>

            <p class="disclaimer">Door op verstuur te drukken ga je akkoord met <br/>de <a href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a> en het <a href="https://stlproduction.blob.core.windows.net/assets/940DD7DF-25DB-427D-8B30-D0F9A77CC6C4" target="_blank">Privacy Statement</a></p>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    .not-visible{
        display:none;
    }

    .small-text{
        font-size: 0.8em;
        color: inherit;
        margin-top:0;
    }

    .date-of-birth-select{
        margin-bottom:0.25rem;
    }
</style>

<script>
    import Vue from 'vue';
    import { TweenMax } from '@/vendor';
    import { labels } from '@/app/config';
    import { fingerprintjs2, recaptcha } from '@/app/mixins';

    import CustomInput from '@/app/components/form-elems/CustomInput';
    import Spinner from '@/app/components/Spinner';
    import CustomCheckbox from '@/app/components/form-elems/CustomCheckbox';
    import CustomRadio from '@/app/components/form-elems/CustomRadio';
    // import CustomSelect from '@/app/components/form-elems/CustomSelect';
    import DateOfBirth from '@/app/components/form-elems/DateOfBirth';

    export default {
        components: {
            'v-input': CustomInput,
            'v-checkbox': CustomCheckbox,
            'v-radio': CustomRadio,
            // 'v-select': CustomSelect,
            'v-date-of-birth': DateOfBirth,
            'v-spinner': Spinner
        },

        mixins: [ fingerprintjs2, recaptcha ],

        data()
        {
            return {
                entry: {
                    score: 0,
                    gender: null,
                    firstname: null,
                    infixname: null,
                    surname: null,
                    date_of_birth: null,
                    zipcode: null,
                    street: null,
                    city: null,
                    house_num: null,
                    house_num_add: null,
                    telephone: null,
                    emailaddress: null,
                    kit: false
                },

                addressError: false,
                waitingForApi: false
            };
        },

        computed: {
            numCorrect()
            {
                return this.$store.getters['quiz/numCorrect'];
            },

            previousEntry()
            {
                return this.$store.getters['entry/get'];
            }
        },

        watch: {
            'entry.zipcode': function( pVal )
            {
                let val = pVal;
                val = val.replace( /\s/g, '' );
                val = val.toUpperCase();
                this.entry.zipcode = val;
            },

            'entry.telephone': function( pVal )
            {
                pVal = pVal.replace( /[-\s]/g, '' );
                pVal = pVal.replace( /^(\+31)/g, '0' );
                pVal = pVal.replace( /^(0031)/g, '0' );

                this.entry.telephone = pVal;
            },

            'entry.infixname': function( pVal )
            {
                pVal = pVal.replace( /\d/g, '' );

                this.entry.infixname = pVal;
            },

            'entry.date_of_birth': function( pVal )
            {
                // console.log( pVal );
            }
        },

        mounted()
        {
            if( this.previousEntry )
            {
                this.entry = Object.assign({}, this.entry, this.previousEntry );
            }
        },

        methods: {
            validate()
            {
                return this.$validator.validateAll( Object.keys( this.entry ) ).then( result =>
                {
                    // console.log( this.entry.street, this.entry.city );
                    console.log( result, this.$validator.errors );
                });
            },

            stripSpaces( e )
            {
                this.entry[ e.target.name ] = e.target.value.replace( /[\s ]/g, '' );
                Vue.nextTick( () =>
                {
                    this.$validator.validate( e.target.name, this.entry[ e.target.name ] );
                });
            },

            checkAddress( e )
            {
                if( e )
                {
                    this.stripSpaces( e );
                }

                if( !this.entry.zipcode || !this.entry.house_num )
                {
                    return;
                }

                if( this.lastAddress === this.entry.zipcode + this.entry.house_num + this.entry.house_num_add )
                {
                    return;
                }
                this.addressError = false;
                this.lastAddress = this.entry.zipcode + this.entry.house_num + this.entry.house_num_add;
                if( this.entry.zipcode.length > 5 && this.entry.house_num.length > 0 )
                {
                    let url = window._rootData.apiUrl + '/api/address/' + this.entry.zipcode + '/' + this.entry.house_num;
                    if( this.entry.house_num_add && this.entry.house_num_add.length > 0 )
                    {
                        url += '/' + this.entry.house_num_add;
                    }
                    this.waitingForAddress = true;
                    Vue.$http.post( url ).then( ( result ) =>
                    {
                        if( result.data.errorCode )
                        {
                            throw new Error( result.data.message );
                        }
                        this.entry.street = result.data.street;
                        this.entry.city = result.data.city;
                        this.waitingForAddress = false;
                    }).catch( ( result ) =>
                    {
                        this.addressError = true;
                        this.entry.street = null;
                        this.entry.city = null;
                        this.waitingForAddress = false;
                    });
                }
            },

            async onSubmit()
            {
                if( !this.entry.street || !this.entry.city )
                {
                    // console.log( 'here' );
                    await this.checkAddress();

                    if( this.entry.street && this.entry.city )
                    {
                        // console.log( 'found city and street, clearing validator' );
                        this.$validator.reset();
                    }
                }

                await this.validate();
                this.entry.score = this.numCorrect;

                // fixing quantum state
                if( this.entry.street && this.$validator.errors.has( 'street' ) )
                {
                    // console.log( 'street quantum state' );

                    this.$validator.errors.update( 'street' );
                }

                if( this.entry.city && this.$validator.errors.has( 'city' ) )
                {
                    // console.log( 'city quantum state' );

                    this.$validator.errors.update( 'city' );
                }

                await Vue.nextTick( () => {}); // kennelijk wordt errors.update pas geupdate na 1 tick

                // console.log( this.$validator.errors.any(), this.$validator.errors );

                if( !this.$validator.errors.any() )
                {
                    if( window.location.href.indexOf( '192.168' ) > -1 )
                    {

                    }
                    else
                    {
                        this.entry['g-recaptcha-response'] = await this.executeRecaptcha();
                    }

                    this.waitingForApi = true;

                    this.$store.dispatch( 'entry/save', this.entry ).then( result =>
                    {
                        this.waitingForApi = false;

                        if( result.id )
                        {
                            this.$emit( 'complete' );
                        }
                    })
                        .catch( error =>
                        {
                            this.waitingForApi = false;

                            for( let field in error.errors )
                            {
                                // console.log( field, labels[ field ], error.errors[ field ][0] );
                                this.$validator.errors.add({
                                    field: field,
                                    msg: error.errors[ field ][0].replace( field, labels[ field ] )
                                });
                            }
                        });
                }

                if( this.$validator.errors.any() )
                {
                    let errorOffsetTop = this.$el.querySelector( 'span.error:not(:empty)' ).getBoundingClientRect().top;
                    TweenMax.to( 'html,body', 0.5, { scrollTop: errorOffsetTop });
                }
            }
        }
    };
</script>
