<template>
    <div class="example-view">
        <!-- <h1 class="main-header">Wat weet jij van Nederland?</h1> -->
        <div class="question-wrapper">
            <v-frame>
                <v-timer slot="top"></v-timer>

                <div class="image"></div>

            </v-frame>

            <v-answermodule @answer="onAnswer" :stelling="'Dit is een laptop'" :correctAnswer="true"></v-answermodule>
        </div>

        <v-panel :open="showExplanation">
            <h2>- Speluitleg -</h2>
            <p>We tonen 50 seconden lang verschillende stellingen. Aan jou de taak om zo snel mogelijk te beoordelen of deze JUIST of ONJUIST zijn.</p>
            <p>Hoe meer stellingen je goed beoordeelt, hoe meer winkansen je verdient. Denk niet te lang na, want je hebt maar 50 seconden!</p>

            <p>Laten we eerst even oefenen.</p>

            <button @click="hideExplain">Voorbeeld stelling</button>
        </v-panel>

        <v-panel :open="correct !== null && toQuiz === false">
            <h2>- {{ correct ? 'JUIST' : 'ONJUIST' }} -</h2>
            <p>Deze stelling was natuurlijk JUIST.</p>
            <p>Laten we beginnen met het echte spel. Beoordeel zoveel mogelijk stellingen correct binnen 50 seconden.</p>
            <p>Met ieder correct antwoord verdien je een <strong>extra</strong> winkans!</p>

            <button @click="gotoQuiz">Start</button>
        </v-panel>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';
    import Frame from '@/app/components/Frame';
    import Timer from '@/app/components/Timer';
    import Panel from '@/app/components/Panel';
    import AnswerModule from '@/app/components/AnswerModule';

    export default {
        components: {
            'v-frame': Frame,
            'v-timer': Timer,
            'v-panel': Panel,
            'v-answermodule': AnswerModule
        },

        data()
        {
            return {
                correct: null,
                showExplanation: true,
                toQuiz: false
            };
        },

        methods: {
            onAnswer( pCorrect )
            {
                this.correct = pCorrect;
            },

            hideExplain()
            {
                this.showExplanation = false;
            },

            gotoQuiz()
            {
                this.toQuiz = true;
                this.$router.push( '/quiz' );
            },

            getAnimateIn()
            {
                let tl = new TimelineMax();
                tl.from( '.example-view', 0.4, { opacity: 0 });

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();
                // tl.to( '.question-wrapper', 0.4, { x: -200, autoAlpha: 0 });
                tl.add( () =>
                {
                }, 0.5 );
                return tl;
            }
        }
    };
</script>
