import EntryProxy from '@/app/proxies/EntryProxy';

export default {
    namespaced: true,
    state: {
        entry: {
            score: 0,
            /* gender: null,
            firstname: null,
            infixname: '',
            surname: null,
            date_of_birth: null,
            zipcode: '',
            street: null,
            city: null,
            house_num: null,
            house_num_add: null,
            telephone: '',
            emailaddress: null,
            kit: false, */
            nyxid: false
        },
        formType: 'a'
    },

    getters: {
        get: state => state.entry,
        /* get: state => ( values ) =>
        {
            if( values )
            {
                let data = { values } = state.entry;
                return data;
            }
            return state.entry;
        }, */

        getId: state =>
        {
            if( state.entry )
            {
                return state.entry.id;
            }

            return null;
        },

        formType: state => state.formType
    },

    mutations: {
        assign: ( state, payload ) =>
        {
            state.entry = Object.assign({}, state.entry, payload );
        },

        setAB: ( state, payload ) =>
        {
            state.formType = payload;
        },

        resetId: state =>
        {
            state.entry.id = null;
        }
    },

    actions: {
        reset: ( context, payload ) =>
        {
            context.commit( 'resetId' );
        },

        merge: ( context, payload ) =>
        {
            context.commit( 'assign', payload );
        },

        save: ( context, payload ) =>
        {
            context.commit( 'assign', payload );

            let entry = context.getters.get;
            // entry.form_type = context.getters.formType;

            return new EntryProxy()
                .create( entry )
                .then( response =>
                {
                    context.commit( 'assign', response );
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        updateShare: ( context, payload ) =>
        {
            payload[ 'id' ] = context.getters.getId;

            return new EntryProxy()
                .update( context.getters.getId, payload )
                .then( response =>
                {
                    return response;
                })
                .catch( error =>
                {
                    return Promise.reject( error );
                });
        },

        setABForm: ( context, payload ) =>
        {
            if( payload )
            {
                context.commit( 'setAB', payload );
                return;
            }

            let version = 'a';
            if( Math.random() > 0.5 )
            {
                version = 'b';
            }

            context.commit( 'setAB', version );
        }
    }
};
