<template>
    <div class="frame-wrapper">
        <slot name="top">
        </slot>

        <div class="frame" :style="{ 'height': height + 'px' }" ref="frame">
            <!-- <div class="frame-content">

            </div> -->
            <img class="droomsalaris-elements" src="/static/img/droomsalaris-elements.png" />

            <div class="frame-content">
                <slot></slot>

                <div v-if="isReplay" class="countdown-wrapper">
                    <span class="countdown">{{ countdownNumber }}</span>
                </div>
            </div>
            <!--
            <div>
                <slot name="bottom"></slot>
            </div> -->
        </div>
        <div class="bottom-slot">
            <slot name="bottom"></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .flash, .flash2{
        position:absolute;
        width:8%;
        bottom:0;
        transform-origin:50% 50%;
    }
</style>

<script>
    import { TimelineMax, TweenMax } from '@/vendor';

    export default {
        data()
        {
            return {
                height: 0,
                countdownNumber: 3
            };
        },

        computed: {
            isReplay()
            {
                return this.$store.getters[ 'quiz/isReplay' ] && this.$route.name === 'Quiz';
                // return this.$route.name === 'Quiz';
            }
        },

        mounted()
        {
            this.calcHeightRef = this.calcHeight;
            window.addEventListener( 'resize', this.calcHeightRef );
            this.calcHeight();

            this.timelines = [];

            // this.makeFlashAnim( '.flash', 2 );
            // this.makeFlashAnim( '.flash2', 2.8 );

            if( this.isReplay )
            {
                this.startCountdown();
            }
            else
            {
                this.$emit( 'startTime' );
            }
        },

        destroyed()
        {
            window.removeEventListener( 'resize', this.calcHeightRef );
            for( let i = 0; i < this.timelines.length; i++ )
            {
                this.timelines[i].kill();
            }
        },

        methods: {
            calcHeight()
            {
                let width = this.$refs.frame.getBoundingClientRect().width;
                this.height = width;

                if( this.$el.classList.contains( 'full-gold' ) )
                {
                    this.height = width;
                }
            },

            makeFlashAnim( selector, time )
            {
                var flashTl = new TimelineMax({ repeat: -1, repeatDelay: Math.random() });

                flashTl.add( () =>
                {
                    let side = Math.floor( Math.random() * 4 );
                    let offset = Math.floor( Math.random() * 92 );

                    TweenMax.set( selector, { left: 'auto', top: 'auto', right: 'auto', bottom: 'auto' });

                    if( side < 2 )
                    {
                        // top or bottom
                        let lightDistanceBetween = 7.65;
                        let realOffset = Math.floor( offset / lightDistanceBetween ) * lightDistanceBetween;

                        TweenMax.set( selector, { left: realOffset + '%', [side === 0 ? 'top' : 'bottom']: 0 });
                    }
                    else
                    {
                        let lightDistanceBetween = 12.38;
                        let realOffset = Math.floor( offset / lightDistanceBetween ) * lightDistanceBetween;

                        TweenMax.set( selector, { top: realOffset + '%', [side === 2 ? 'left' : 'right']: 0 });
                    }
                });

                flashTl.from( selector, 0.3, { opacity: 0, scale: 0.5, rotation: -20 });
                flashTl.to( selector, time - 0.3, { opacity: 0, scale: 1.5, rotation: 20 }, 0.3 );

                this.timelines.push( flashTl );
            },

            startCountdown()
            {
                TweenMax.set( '.countdown', { rotation: -45 });

                let tl = new TimelineMax();
                tl.from( '.countdown-wrapper', 0.3, { opacity: 0 }, 0 );
                tl.from( '.countdown', 0.3, { scale: 0, rotation: 10 }, -0.3 );

                tl.to( '.countdown', 0.3, { scale: 0, rotation: 10, yoyo: true, repeat: 1, ease: Expo.easeInOut }, 1 );
                tl.add( () =>
                {
                    this.countdownNumber--;
                }, 1.3 );

                tl.to( '.countdown', 0.3, { scale: 0, rotation: 10, yoyo: true, repeat: 1, ease: Expo.easeInOut }, 2 );
                tl.add( () =>
                {
                    this.countdownNumber--;
                }, 2.3 );

                tl.to( '.countdown', 0.3, { scale: 0, rotation: 10, yoyo: true, repeat: 1, ease: Expo.easeInOut }, 3 );
                tl.add( () =>
                {
                    this.countdownNumber--;
                }, 3.3 );

                tl.to( '.countdown', 0.3, { scale: 0, rotation: 10, ease: Expo.easeIn }, 4 );
                tl.to( '.countdown-wrapper', 0.3, { opacity: 0 }, 4 );
                tl.add( () =>
                {
                    this.$emit( 'startTime' );
                });
            }
        }
    };
</script>
