<template>
    <div class="form-step3">
        <h2>De laatste stap!</h2>

        <div class="indicator-wrapper">
            <div class="dot active">1</div>
            <div class="line"></div>
            <div class="dot active">2</div>
            <div class="line"></div>
            <div class="dot">3</div>
        </div>

        <div class="form-wrapper">
            <form @submit.prevent="onSubmit">
                <v-input class="telephone-row" name="telephone" v-model="entry.telephone" ></v-input>
                <p class="small">Vul hier je telefoonnummer in en ontvang eenmalig een aanbod van de Staatsloterij of Lotto om automatisch mee te spelen.</p>

                <v-input name="emailaddress" v-model="entry.emailaddress" ></v-input>

                <v-checkbox name="kit" v-model="entry.kit" >
                    Ik wil per e-mail relevante aanbiedingen van de (toekomstige) kansspelen van Nederlandse Loterij ontvangen (dit kun je later altijd wijzigen)
                </v-checkbox>

                <button type="submit" :disabled="waitingForApi">Verstuur <v-spinner v-if="waitingForApi"></v-spinner></button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .form-wrapper{
        margin:0 auto;
        width:100%;
        max-width:400px;

        button{
            margin:0 auto;
            display:block;
        }
    }

    .not-visible{
        display:none;
    }
</style>

<script>
    import Spinner from '@/app/components/Spinner';
    import CustomInput from '@/app/components/form-elems/CustomInput';
    import CustomCheckbox from '@/app/components/form-elems/CustomCheckbox';
    import { fingerprintjs2, recaptcha } from '@/app/mixins';

    export default {
        components: {
            'v-input': CustomInput,
            'v-checkbox': CustomCheckbox,
            'v-spinner': Spinner
        },

        mixins: [ fingerprintjs2, recaptcha ],

        data()
        {
            return {
                entry: {
                    telephone: null,
                    emailaddress: null,
                    kit: false
                },

                waitingForApi: false
            };
        },

        computed: {
            savedEntry()
            {
                return this.$store.getters['entry/get'];
            },

            numCorrect()
            {
                return this.$store.getters['quiz/numCorrect'];
            }
        },

        watch: {
            'entry.telephone': function( pVal )
            {
                if( !pVal )
                {
                    return;
                }
                pVal = pVal.replace( /[-\s]/g, '' );
                pVal = pVal.replace( /^(\+31)/g, '0' );
                pVal = pVal.replace( /^(0031)/g, '0' );

                this.entry.telephone = pVal;
            }
        },

        mounted()
        {
            if( this.savedEntry )
            {
                this.entry.telephone = this.savedEntry.telephone;
                this.entry.emailaddress = this.savedEntry.emailaddress;
            }
        },

        methods: {
            validate()
            {
                return this.$validator.validateAll( Object.keys( this.entry ) ).then( result =>
                {
                    console.log( result, this.$validator.errors );
                });
            },

            async onSubmit()
            {
                await this.validate();

                if( !this.$validator.errors.any() )
                {
                    this.entry.score = this.numCorrect;
                    this.entry['g-recaptcha-response'] = await this.executeRecaptcha();

                    this.waitingForApi = true;
                    this.$store.dispatch( 'entry/save', this.entry ).then( result =>
                    {
                        this.waitingForApi = false;

                        if( result.id )
                        {
                            this.$router.push( '/delen' );
                        }
                    })
                        .catch( error =>
                        {
                            this.waitingForApi = false;
                            console.log( error );
                        });

                    // this.$router.push( '/form3' );
                }
            }
        }
    };
</script>
