<template>
    <div>
        <div class="answer-module">
            <button @click="giveAnswer( $event, false )">
                <img src="/static/img/onjuist.png" />
            </button>

            <div class="stelling">
                <transition @enter="onEnterStelling" @leave="onLeaveStelling" :mode="browserCheck.isIE ? 'out-in': ''">
                    <span :key="stelling">{{ stelling }}</span>
                </transition>
            </div>

            <button class="goed" @click="giveAnswer( $event, true )">
                <img src="/static/img/juist.png" />
            </button>
        </div>
        <div ref="plopperContainer" class="plopper-container">
            <!-- <div class="plopper" ref="plopper"></div> -->
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { TweenMax, TimelineMax } from '@/vendor';
    import { browserCheck } from '@/app/mixins';

    export default {
        mixins: [ browserCheck ],

        props: {
            stelling: {
                type: String,
                default: 'Dit is een kokosnoot'
            },

            correctAnswer: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            giveAnswer( e, pAnswer )
            {
                if( !this.$refs.plopperContainer )
                {
                    return;
                }

                let plopperRef = document.createElement( 'div' );
                this.$refs.plopperContainer.appendChild( plopperRef );

                TweenMax.set( plopperRef, { top: e.pageY - 15 + 'px', left: e.pageX - 15 + 'px', y: 0, x: 0 });

                if( pAnswer === this.correctAnswer )
                {
                    plopperRef.style.backgroundImage = 'url(/static/img/quiz/goed.png)';
                }
                else
                {
                    plopperRef.style.backgroundImage = 'url(/static/img/quiz/fout.png)';
                }

                let tl = new TimelineMax();
                tl.fromTo( plopperRef, 0.5, { autoAlpha: 0, scale: 0.5 }, { autoAlpha: 1, scale: 1, ease: Back.easeOut.config( 2 ) });

                tl.to( plopperRef, 1, { y: -300, ease: Sine.easeIn }, 0 );
                tl.to( plopperRef, 0.2, { autoAlpha: 0 }, 0.8 );
                tl.to( plopperRef, 0.2, { x: 30, yoyo: true, repeat: 4, ease: Sine.easeInOut }, 0 );
                tl.add( () =>
                {
                    if( this.$refs.plopperContainer )
                    {
                        this.$refs.plopperContainer.removeChild( plopperRef );
                    }
                }, 1 );

                this.$emit( 'answer', pAnswer );
            },

            onEnterStelling( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( !this.browserCheck.isIE )
                {
                    // tl.from( el, 0.3, { opacity: 0, position: 'absolute' });
                }

                return tl;
            },

            onLeaveStelling( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                if( !this.browserCheck.isIE )
                {
                    tl.to( el, 0.3, { opacity: 0, position: 'absolute' });
                }
                return tl;
            }
        }
    };
</script>
