import Vue from 'vue';
import Router from 'vue-router';
import store from '@/app/store';
import { TweenMax } from '@/vendor';

import Landing from '@/app/pages/Landing';
import Example from '@/app/pages/Example';
import Quiz from '@/app/pages/Quiz';
import Naw from '@/app/pages/Naw';
import Thanks from '@/app/pages/Thanks';
import Share from '@/app/pages/Share';
import Result from '@/app/pages/Result';

import FormStep1 from '@/app/pages/form/Step1';
import FormStep2 from '@/app/pages/form/Step2';
import FormStep3 from '@/app/pages/form/Step3';

import ActieEinde from '@/app/pages/ActieEinde';

Vue.use( Router );

let appRouter = new Router({
    mode: 'history',
    routes: [
        // generated-routes
        {
            path: '/actie-afgelopen',
            name: 'ActieEinde',
            component: ActieEinde
        },
        {
            path: '/',
            name: 'Landing',
            component: Landing
        },

        {
            path: '/voorbeeld',
            name: 'Example',
            component: Example
        },

        {
            path: '/quiz',
            name: 'Quiz',
            component: Quiz,
            beforeEnter: ( to, from, next ) =>
            {
                store.dispatch( 'quiz/reset' );
                next();
            }
        },

        {
            path: '/gegevens',
            name: 'Naw',
            component: Naw,
            beforeEnter: ( to, from, next ) =>
            {
                TweenMax.to( 'html, body', 0.5, { scrollTop: 0, ease: Sine.easeInOut });
                next();
            }
        },

        {
            path: '/form1',
            name: 'Form1',
            component: FormStep1
        },

        {
            path: '/form2',
            name: 'Form2',
            component: FormStep2
        },

        {
            path: '/form3',
            name: 'Form3',
            component: FormStep3
        },

        {
            path: '/delen',
            name: 'Share',
            component: Share
        },

        {
            path: '/result',
            name: 'Result',
            component: Result
        },

        {
            path: '/bedankt',
            name: 'Thanks',
            component: Thanks
        }
    ]
});

appRouter.beforeEach( ( to, from, next ) =>
{
    let trackingAlias = {
        '/': '/landing',
        '/voorbeeld': '/voorbeeld',
        '/quiz': '/quiz',
        '/gegevens': '/gegevens',
        '/form1': '/gegevens1',
        '/form2': '/gegevens2',
        '/form3': '/gegevens3',
        '/delen': '/delen',
        '/bedankt': '/bedankt'
    };

    dataLayer.push({
        event: 'pageview',
        page_path: trackingAlias[ to.fullPath ]
    });

    // if( to.fullPath === '/bedankt' )
    // {
    //     let hasKit = store.getters['entry/get'].kit;
    //     dataLayer.push({
    //         event: 'confirmation',
    //         newsletter_optin: hasKit,
    //         nyx_id: ''
    //     });
    // }

    if( typeof to.beforeEnter === 'function' ) // check if the route has a beforeEnter function
    {
        to.beforeEnter( to, from, next );
        return;
    }

    next();
});

export default appRouter;
