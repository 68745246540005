<template>
    <div :class="{ 'custom-input': true, 'has-errors': errors.has( name ) }">

        <label :for="name"><slot>{{ labels[ name ] }}{{ ( rules[ name ] && rules[ name ].required ) ? '' : '' }}</slot></label>

        <input
            :value="value"
            @input="onInput"
            @blur="updateFieldValidationEvent"
            @focus="onFocus"

            :name="name"
            :id="name"
            :placeholder="placeholders[ name ]"
            :maxlength="attributes[ name ].maxlength ? attributes[ name ].maxlength : 524288"

            :type="inputType"
            v-validate="name ? rules[ name ] : null" />

        <!--
            :value - v-model linkage
            @input - v-model linkage

            :name, :id, v-validate - vee-validate linkage
        -->

        <span class="error">{{ errors.first( name ) }}</span>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { rules, labels, placeholders, attributes } from '@/app/config';
    import { tracking } from '@/app/mixins';

    export default {
        inject: [ '$validator' ],

        mixins: [ tracking ],

        model: {
            prop: 'value',
            event: 'input'
        },

        props: {
            value: {
                type: String,
                default: null
            },

            name: {
                type: String,
                default: null
            }
        },

        data()
        {
            return {
                rules: rules,
                labels: labels,
                placeholders: placeholders,
                attributes: attributes
            };
        },

        computed: {
            hasErrors()
            {
                return this.$validator.errors.has( this.name );
            },

            inputType()
            {
                switch( this.name )
                {
                        case 'emailaddress':
                            return 'email';
                        case 'telephone':
                            return 'tel';
                        case 'house_num':
                            return 'number';
                        default:
                            return 'text';
                }
            }
        },

        methods: {
            onFocus( e )
            {
                this.trackEvent( 'Klik op input', 'Funnel events', this.labels[ this.name ] );
            },

            onInput( e )
            {
                // optionally do stuff globally
                var value = e.target.value;

                // emit
                this.$emit( 'input', value );
            },

            updateFieldValidationEvent( e )
            {
                let field = this.$validator.fields.find({ name: this.name });

                if( !field )
                {
                    return;
                }

                if( this.$validator.errors.has( this.name ) )
                {
                    field.update({ events: 'input|blur' });
                    return;
                }

                field.update({ events: 'change' });
                this.$emit( 'blur', e );
            }
        }
    };
</script>
