<template>
    <div class="form-step1">
        <h2>Goed gedaan!</h2>
        <p>Registreer je deelname en je ziet direct <br/>hoeveel winkansen je bij elkaar gespeeld hebt!</p>

        <div class="indicator-wrapper">
            <div class="dot">1</div>
            <div class="line hide"></div>
            <div class="dot hide">2</div>
            <div class="line hide"></div>
            <div class="dot hide">3</div>
        </div>

        <div class="form-wrapper">
            <form @submit.prevent="onSubmit">
                <v-radio name="gender" v-model="entry.gender" :radioValues="[ 'male', 'female' ]" :radioLabels="[ 'Man', 'Vrouw' ]" tabindexStart="1" ></v-radio>

                <div class="row">
                    <div class="col-8">
                        <v-input name="firstname" v-model.trim="entry.firstname" ></v-input>
                    </div>
                    <div class="col-4">
                        <v-input name="infixname" v-model.trim="entry.infixname" ></v-input>
                    </div>
                </div>

                <v-input name="surname" v-model="entry.surname" ></v-input>

                <v-date-of-birth name="date_of_birth" v-model="entry.date_of_birth"></v-date-of-birth>

                <button type="submit">Naar adresgegevens</button>
            </form>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import CustomRadio from '@/app/components/form-elems/CustomRadio';
    import CustomInput from '@/app/components/form-elems/CustomInput';
    import DateOfBirth from '@/app/components/form-elems/DateOfBirth';

    export default {
        components: {
            'v-radio': CustomRadio,
            'v-input': CustomInput,
            'v-date-of-birth': DateOfBirth
        },

        data()
        {
            return {
                entry: {
                    gender: null,
                    firstname: null,
                    infixname: null,
                    surname: null,
                    date_of_birth: null
                }
            };
        },

        computed: {
            savedEntry()
            {
                return this.$store.getters['entry/get'];
            }
        },

        watch: {
            'entry.infixname': function( pVal )
            {
                if( !pVal )
                {
                    return;
                }
                pVal = pVal.replace( /\d/g, '' );

                this.entry.infixname = pVal;
            }
        },

        mounted()
        {
            if( this.savedEntry )
            {
                this.entry.gender = this.savedEntry.gender;
                this.entry.firstname = this.savedEntry.firstname;
                this.entry.infixname = this.savedEntry.infixname;
                this.entry.surname = this.savedEntry.surname;
                this.entry.date_of_birth = this.savedEntry.date_of_birth;
            }
        },

        methods: {
            validate()
            {
                return this.$validator.validateAll( Object.keys( this.entry ) ).then( result =>
                {
                    // console.log( this.entry.street, this.entry.city );
                    console.log( result, this.$validator.errors );
                });
            },

            async onSubmit()
            {
                await this.validate();

                if( !this.$validator.errors.any() )
                {
                    this.$store.dispatch( 'entry/merge', this.entry );
                    this.$router.push( '/form2' );
                }
            }
        }
    };
</script>
