<template>
    <div class="landing-view">
        <!-- <h1 class="main-header">Wat weet jij van Nederland?</h1> -->
        <v-frame class="frame">
            <v-timer slot="top"></v-timer>

            <div class="landing-content">
                <div class="gold">
                    <h2>Maak in 50 seconden<br/>kans op 1 van de 50<br/>Straatjes Staatsloten!</h2>
                </div>
            </div>

            <div slot="bottom" class="avg-accept" v-if="isPlayer">
                <p>als je meedoet aan deze actie ga je akkoord met de <a href="/static/pdf/actievoorwaarden.pdf" target="_blank">voorwaarden</a>*.</p>
                <v-spinner v-if="!preloadDone"></v-spinner>

                <button @click="acceptAvg" class="accept-avg-btn" v-else-if="!avgAccepted">Akkoord</button>

                <button @click="onContinue" class="continue-btn" v-else>Doe mee</button>
            </div>

            <!-- <button slot="bottom" class="start-button" @click="onContinue">
                <v-spinner v-if="!preloadDone"></v-spinner>
                <template v-else>Doe mee</template>
            </button> -->
        </v-frame>

        <v-panel :open="!isPlayer">
            <h2>Let op!</h2>
            <p>Dit spel is alleen voor mensen die automatisch meespelen met de Staatsloterij.</p>
            <p>Wil je voortaan ook toegang tot exclusieve acties? Speel dan nu automatisch mee en ontvang eenmalig een gratis lot!</p>

            <a class="button" href="https://staatsloterij.nederlandseloterij.nl/automatisch-meespelen" target="_blank">Automatisch meespelen</a>
            <!-- <button @click="hideNyxidPopup">Toch meedoen</button> -->
        </v-panel>
        <!-- <div v-if="!preloadDone" class="progress-bar"><div class="inner" :style="{ width: preloadPercentage + '%' }"></div></div> -->
    </div>
</template>

<style lang="scss">
    .avg-accept{
        p{
            font-size:0.7em;
            max-width: 75%;
            margin: 0 auto;
            margin-bottom: 0.5rem;
        }
    }
</style>

<script>
    // import QuizData from '@/app/data/quiz.json';
    import { browserCheck } from '@/app/mixins';
    import Timer from '@/app/components/Timer';
    import Frame from '@/app/components/Frame';
    import Panel from '@/app/components/Panel';
    import Spinner from '@/app/components/Spinner';
    import { TimelineMax, TweenMax } from '@/vendor';

    export default {
        name: 'Landing',

        components: {
            'v-timer': Timer,
            'v-frame': Frame,
            'v-panel': Panel,
            'v-spinner': Spinner
        },

        mixins: [ browserCheck ],

        data()
        {
            return {
                preloadPercentage: 0,
                preloadDone: true,
                avgAccepted: true
            };
        },

        computed: {
            hasPreloaded()
            {
                return this.$store.getters['quiz/questions']( 0 ).visual.indexOf( 'blob' ) >= 0;
            },

            isPlayer()
            {
                return this.$store.getters['entry/get'].nyxid;
            }
        },

        mounted()
        {
            if( this.browserCheck.isIE )
            {
                this.preloadDone = true;
            }

            // this.preloadImages();

            let otherImages = [
                '/static/img/timer.png',
                '/static/img/juist.png',
                '/static/img/onjuist.png'
            ];

            for( let path of otherImages )
            {
                let img = new Image();
                img.src = path;
            }
        },

        methods: {
            acceptAvg()
            {
                // AVG: de beste virusscanner
                let tl = new TimelineMax();

                tl.to( '.accept-avg-btn', 0.06, { scale: 0.95, repeat: 1, yoyo: true, ease: Sine.easeInOut }, 0 );

                tl.to( '.avg-accept .accept-avg-btn', 0.5, { x: '-40', opacity: 0 }, 0.2 );
                tl.to( '.avg-accept p', 0.3, { opacity: 0 }, 0.3 );
                tl.add( () =>
                {
                    this.avgAccepted = true;
                    TweenMax.set( '.avg-accept .accept-avg-btn', { x: '0%', opacity: 0 });
                    TweenMax.to( '.avg-accept .accept-avg-btn', 0.2, { opacity: 1 });
                }, 1 );
            },

            onContinue()
            {
                if( this.preloadDone )
                {
                    this.$router.push( '/voorbeeld' );
                }
            },

            getAnimateOut()
            {
                let tl = new TimelineMax({ delay: 0.1 });
                tl.to( '.landing-view', 0.4, { x: -200, autoAlpha: 0 });
                return tl;
            }
        }
    };
</script>
