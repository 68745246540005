<template>
    <div :class="{ 'custom-checkbox': true, 'has-errors': errors.has( name ) }">
        <input
            type="checkbox"
            :checked="checked"
            :id="name"
            :name="name"

            @change="onChange"
            v-validate="rules[ name ]"
            data-vv-validate-on="input" />

        <div class="indicator"></div>

        <label :for="name">
            <!-- <div :class="{ 'indicator': true, 'checked': checked }"></div> -->

            <slot></slot>
        </label>

        <span class="error">{{ errors.first( name ) }}</span>
    </div>
</template>

<style lang="scss" scoped>
    input, label{
        display:inline-block;
    }

    span.error{
        display:block;
    }
</style>

<script>
    import { rules } from '@/app/config';

    export default {
        inject: [ '$validator' ],

        model: {
            prop: 'checked',
            event: 'change'
        },

        props: {
            checked: {
                type: Boolean,
                default: false
            },

            name: {
                type: String,
                default: 'checkbox'
            }
        },

        data()
        {
            return {
                rules: rules
            };
        },

        methods: {
            onChange( e )
            {
                this.$emit( 'change', e.target.checked );
            }
        }
    };
</script>
