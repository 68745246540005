<template>
    <transition @enter="getAnimateIn" @leave="getAnimateOut" appear>
        <div v-if="open" class="simple-panel-wrapper">
            <div class="simple-panel">
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { TimelineMax } from '@/vendor';

    export default {
        props: {
            open: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            getAnimateIn( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                tl.from( '.simple-panel', 0.3, { y: 50, autoAlpha: 0 });
                return tl;
            },

            getAnimateOut( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                tl.to( '.simple-panel', 0.3, { y: 50, autoAlpha: 0 });
                return tl;
            }
        }
    };
</script>
