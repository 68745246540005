import 'babel-polyfill';

import Vue from 'vue';
import App from './App';

import router from '@/app/router';
import store from '@/app/store';
import VuexRouterSync from 'vuex-router-sync';

import Axios from 'axios';

import VeeValidate from 'vee-validate';
import dict from 'vee-validate/dist/locale/nl';
import { i18n } from '@/app/config';

VuexRouterSync.sync( store, router );

Vue.use( VeeValidate, {
    events: 'change'
});

VeeValidate.Validator.localize( 'nl', Object.assign( dict, i18n.nl ) );

Axios.defaults.baseURL = window._rootData.apiUrl;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.interceptors.response.use(
    response => response,
    ( error ) =>
    {
        return Promise.reject( error );
    }
);

Vue.$http = Axios;
Object.defineProperty( Vue.prototype, '$http', {
    get()
    {
        return Axios;
    }
});

let img = new Image();
img.src = '/static/img/timer.png';

if( !window.dataLayer )
{
    window.dataLayer = [];
}

Vue.config.productionTip = false;

// if( window._rootData.formType === 'a' || window._rootData.formType === 'b' )
// {
//     store.dispatch( 'entry/setABForm', window._rootData.formType );
// }
// else
// {
//     store.dispatch( 'entry/setABForm' );
// }

if( window._rootData.test === '1' )
{
    store.dispatch( 'quiz/setNoLimit' );
}

store.dispatch( 'entry/merge', {
    nyxid: window._rootData.player,
    token: window._rootData.token
});

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
    template: '<App/>'
});
