<template>
    <div class="quiz-view" v-if="questionData">
        <!-- <h1 class="main-header">Wat weet jij van Nederland?</h1> -->
        <div class="question-wrapper" :style="{ 'pointer-events': ( preventInput ? 'none' : 'auto' ) }">
            <v-frame @startTime="startTime">
                <v-timer :time="timeLeft" slot="top">
                </v-timer>

                <transition @enter="onEnterQuestion" @leave="onLeaveQuestion">
                    <div
                        :key="questionData.id"
                        :class="{ [ questionData.type ]: true }"
                        :style="{ 'background-image': questionData.type === 'image' ? 'url( ' + questionData.visual + ' )' : '',
                                  'background-color': questionData.type === 'color' ? questionData.visual : '' }" >

                        <h2 v-if="questionData.type === 'text'">{{ questionData.visual }}</h2>
                    </div>

                </transition>

            </v-frame>
            <v-answermodule @answer="onAnswer" :stelling="questionData.stelling" :correctAnswer="questionData.correct"></v-answermodule>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import Vue from 'vue';
    import { TweenMax, TimelineMax } from '@/vendor';
    import Frame from '@/app/components/Frame';
    import Timer from '@/app/components/Timer';
    import AnswerModule from '@/app/components/AnswerModule';
    import { recaptcha, fingerprintjs2 } from '@/app/mixins';

    export default {
        components: {
            'v-frame': Frame,
            'v-timer': Timer,
            'v-answermodule': AnswerModule
        },

        mixins: [ recaptcha, fingerprintjs2 ],

        data()
        {
            return {
                money: 0,
                timeLeft: 50,
                preventInput: true
            };
        },

        computed: {
            currentQuestion()
            {
                // index
                return this.$store.getters[ 'quiz/answers' ].length;
            },

            questionData()
            {
                // actual question data
                return this.$store.getters[ 'quiz/questions' ]( this.currentQuestion );
            },

            numCorrect()
            {
                return this.$store.getters[ 'quiz/numCorrect' ];
            },

            moneyCollected()
            {
                return this.$store.getters[ 'quiz/numCorrect' ] * 50;
            },

            formType()
            {
                return this.$store.getters[ 'entry/formType' ];
            },

            noLimit()
            {
                return this.$store.getters[ 'quiz/noLimit' ];
            }
        },

        watch: {
            currentQuestion: function( pVal )
            {
                if( !this.questionData )
                {
                    // if( this.formType === 'a' )
                    // {
                    //     this.$router.push( '/gegevens' );
                    // }
                    // else
                    // {
                    //     this.$router.push( '/form1' );
                    // }
                    // this.$router.push( '/gegevens' );
                    this.animateEnding();
                }
            },

            moneyCollected: function( pVal )
            {
                TweenMax.to( this, 1, { money: pVal, roundProps: 'money', ease: Circ.easeInOut });
            }
        },

        mounted()
        {
            this.entry = {};

            if( this.noLimit === true )
            {
                this.timeLeft = 500000;
            }

            this.timeTween = TweenMax.to({ foo: 0 }, 1, { foo: 1, onRepeat: this.updateTime, repeat: -1, paused: true });
        },

        destroyed()
        {
            this.timeTween.kill();
        },

        methods: {
            startTime()
            {
                Vue.nextTick( () =>
                {
                    this.preventInput = false;
                    this.timeTween.play();
                });
            },

            updateTime()
            {
                this.timeLeft--;

                if( this.timeLeft <= 0 )
                {
                    this.timeTween.kill();
                    this.animateEnding();
                }
            },

            onAnswer( pCorrect )
            {
                if( !this.timeTween.isActive() )
                {
                    return;
                }

                this.$store.dispatch( 'quiz/answerGiven', { questionId: this.questionData.id, answer: pCorrect }).then( () =>
                {
                });
            },

            animateEnding()
            {
                this.$router.push( '/gegevens' );
                // if( this.formType === 'a' )
                // {
                //     this.$router.push( '/gegevens' );
                // }
                // else
                // {
                //     this.$router.push( '/form1' );
                // }
            },

            onEnterQuestion( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });
                tl.from( el, 0.3, { opacity: 0 }, 0 );
                return tl;
            },

            onLeaveQuestion( el, done )
            {
                let tl = new TimelineMax({ onComplete: done });

                tl.to( el, 0.3, { opacity: 0 }, 0 );

                return tl;
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();

                tl.to( this.$el, 0.5, { opacity: 0 }, 0 );

                return tl;
            }
        }
    };
</script>
