export default {
    gender: {},
    firstname: {},
    infixname: {},
    surname: {},
    day_of_birth: {},
    month_of_birth: {},
    year_of_birth: {},
    zipcode: {
        maxlength: 7
    },
    house_num: {},
    house_num_add: {},
    emailaddress: {},
    telephone: {},
    street: {},
    city: {}
};
