<template>
    <div class="result-view">
        <v-frame class="full-gold">
            <v-timer slot="top"></v-timer>

            <div class="gold">
                <h2>Jouw resultaat!</h2>
                <p style="margin-bottom:0;">Je hebt <strong>{{ numCorrect }} stellingen</strong> goed beantwoord!</p>

                <p style="margin-top:1rem;">Daarmee heb je <strong>{{ numCorrect + ( hasShared ? shareAmount : 0 ) }} winkansen</strong> verzameld op <strong>1 van de 50 Straatjes Staatsloten.</strong></p>
            </div>

            <button slot="bottom" @click="toThanks">Verder</button>

        </v-frame>
    </div>
</template>

<style lang="scss" scoped>
    .extra-margin{
        margin-top:2rem;
    }
</style>

<script>
    import Frame from '@/app/components/Frame';
    import Timer from '@/app/components/Timer';
    // import Spinner from '@/app/components/Spinner';
    import { browserCheck, recaptcha, fingerprintjs2 } from '@/app/mixins';
    import { TimelineMax } from '@/vendor';

    export default {
        components: {
            'v-frame': Frame,
            'v-timer': Timer
            // 'v-spinner': Spinner
        },

        mixins: [ browserCheck, recaptcha, fingerprintjs2 ],

        data()
        {
            return {
                waitingForApi: false
            };
        },

        computed: {
            numCorrect()
            {
                return this.$store.getters[ 'quiz/numCorrect' ];
            },

            answersGiven()
            {
                return this.$store.getters[ 'quiz/answers' ].length;
            },

            hasShared()
            {
                return false;
                // if( !this.$store.getters['entry/get'] )
                // {
                //     return false;
                // }
                // return this.$store.getters['entry/get'].has_shared;
            },

            shareAmount()
            {
                return 5;
            }
        },

        mounted()
        {
            this.entry = {};
            // this.saveEntry();
        },

        methods: {
            async saveEntry()
            {
                this.entry['g-recaptcha-response'] = await this.executeRecaptcha();
                this.entry['score'] = this.numCorrect;

                this.waitingForApi = true;

                this.$store.dispatch( 'entry/save', this.entry ).then( () =>
                {
                    // this.$router.push( '/bedankt' );
                    this.waitingForApi = false;
                });
            },

            toThanks()
            {
                this.$router.push( '/bedankt' );
            },

            getAnimateOut()
            {
                let tl = new TimelineMax();

                tl.to( '.gold > *', 0.3, { opacity: 0 }, 0 );

                return tl;
            }
        }
    };
</script>
